module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Prompt:300,400,500,600,700","Sarabun:100,300,400,500,600,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ดีบุญ","short_name":"Deeboon","description":"สร้างความมั่นคง ให้กับธุรกิจของคุณ เพื่อดูแลผู้คนของคุณ และมาร่วมกันส่งต่อประโยช์ที่คุณได้ออกไปสู่ผู้อื่น","start_url":"/","background_color":"#f09ea3","theme_color":"#272f41","display":"standalone","icon":"static/logo/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"16465c08a0e4d73cd62bfc44d0ea736e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"importWorkboxFrom":"cdn"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
